import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import seaturtle from "../images/seaturtle.jpg";
import backgroundImg from "../images/artificial-reefs-background.JPG";

const backgroundPhoto = {
  backgroundImage: `url(${backgroundImg})`,
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const jumbotronStyle = {
  background: "rgb(45, 64, 89, 0.7)",
  color: "#F6F6F6",
};

const Jumbotron = () => {
  return (
    <>
      <Container fluid style={backgroundPhoto} className='py-5'>
        <Container className='my-5 py-5'>
          <Container
            className='my-5 p-5 pb-0 pe-lg-0 align-items-center rounded-3'
            style={jumbotronStyle}
          >
            <Row>
              <Col className='m-auto py-4' md={7}>
                <h1 className='display-5 fw-bold 1h-1'>
                  Creating Habitat with Artificial Reefs
                </h1>
                <p className='lead'>
                  The Reefmaker - Walter Marine deployed its first artificial
                  reef in 1986. Today they are the largest reef builders in the
                  United States with over 70,000 reefs deployed. All of our
                  artificial reefs feature our patented design of natural reef
                  like material. These artificial reefs provide a habitat for
                  all forms of marine life, from juveniles to adults. The reefs
                  are durable enough to withstand hurricane 5 force winds and
                  waves. These limestone reefs can be created in any size,
                  including the largest available in the US. There are four
                  divisions of reefs: fishing reefs, snorkeling reefs, wave
                  attenuators, and ships.
                </p>
              </Col>
              <Col md='5'>
                <img
                  className='img-fluid mx-auto rounded-3 p-4'
                  src={seaturtle}
                  alt=''
                  height={"720"}
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default Jumbotron;
