import React from "react";
import { TopMenu } from "./components/nav/TopMenu";
import Footer from "./components/nav/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { Helmet } from "react-helmet";
import Gallery from "./components/Gallery";

const App = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Reefmaker - Walter Marine</title>
        <meta
          name='description'
          content='The Reefmaker – Walter Marine has deployed over 60,000 artificial reefs since 1986, and they are the largest reef builder in the United States.'
        ></meta>
        <meta
          name='keywords'
          content='Reefmaker, Artificial Reefs, Watler Marine'
        ></meta>
      </Helmet>
      <TopMenu />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/photos-videos' element={<Gallery />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
