import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import img1 from "../images/ship.jpg";
import img2 from "../images/ship2.jpg";
import img3 from "../images/ship3.jpg";
import img4 from "../images/ship4.jpg";
import img5 from "../images/ship5.jpg";
import img6 from "../images/ship6.jpg";
import img7 from "../images/ship7.jpg";
import img8 from "../images/ship8.jpg";
import img9 from "../images/ship9.jpg";
import img10 from "../images/ship10.jpg";
import img11 from "../images/ship11.jpg";
import img12 from "../images/ship12.jpg";
import img13 from "../images/ship13.jpg";
import img14 from "../images/ship14.jpg";
import img15 from "../images/ship15.jpg";
import img16 from "../images/reef2.jpg";
import img17 from "../images/reef4.jpg";
import img18 from "../images/reef5.jpg";
import img19 from "../images/reef6.jpg";
import img20 from "../images/reef7.jpg";
import img21 from "../images/reef9.jpg";
import img22 from "../images/reef10.jpg";
import img23 from "../images/reef11.jpg";
import img24 from "../images/reef12.jpg";
import img25 from "../images/reef13.jpg";
import img26 from "../images/reef14.jpg";
import img27 from "../images/reef15.jpg";
import img28 from "../images/reef16.jpg";
import img29 from "../images/reef17.jpg";
import img30 from "../images/reef18.jpg";
import img31 from "../images/reef19.jpg";
import img32 from "../images/reef20.jpg";
import img33 from "../images/reef21.jpg";
import img34 from "../images/reef22.jpg";
import img35 from "../images/reef23.jpg";
import img36 from "../images/reef24.jpg";
import img37 from "../images/reef25.jpg";
import img38 from "../images/reef26.jpg";
import img39 from "../images/reef27.jpg";
import img40 from "../images/reef28.jpg";
import img41 from "../images/reef29.jpg";
import img42 from "../images/reef30.jpg";
import img43 from "../images/reef31.jpg";
import img44 from "../images/reef32.jpg";
import img45 from "../images/reef33.jpg";
import img46 from "../images/reef34.jpg";
import img47 from "../images/reef35.jpg";
import img48 from "../images/reef36.jpg";
import img49 from "../images/reef37.jpg";
import img50 from "../images/reef38.jpg";
import img51 from "../images/reef39.jpg";
import img52 from "../images/reef40.jpg";
import img53 from "../images/reef41.jpg";
import img54 from "../images/reef42.jpg";
import img56 from "../images/reef44.jpg";
import img57 from "../images/reef45.jpg";
import img58 from "../images/reef46.jpg";
import img59 from "../images/reef47.jpg";
import img60 from "../images/reef48.jpg";
import img61 from "../images/reef49.jpg";
import img62 from "../images/reef50.jpg";
import img63 from "../images/reef51.jpg";
import img64 from "../images/reef52.jpg";
import img65 from "../images/reef53.jpg";
import img66 from "../images/reef54.jpg";
import img67 from "../images/reef55.jpg";
import img68 from "../images/reef56.jpg";
import img69 from "../images/reef57.jpg";
import img70 from "../images/reef58.jpg";
import img71 from "../images/reef59.jpg";
import img72 from "../images/reef60.jpg";
import img73 from "../images/reef61.jpg";
import img74 from "../images/reef62.jpg";
import img75 from "../images/reef63.jpg";
import img76 from "../images/reef64.jpg";
import img77 from "../images/reef65.jpg";
import img78 from "../images/reef66.jpg";
import img79 from "../images/reef67.jpg";
import img80 from "../images/reef68.jpg";
import img81 from "../images/reef69.jpg";
import img82 from "../images/reef70.jpg";
import img83 from "../images/reef71.jpg";
import img84 from "../images/reef72.jpg";
import img85 from "../images/reef73.jpg";
import img86 from "../images/reef74.jpg";
import img87 from "../images/reef75.jpg";
import img88 from "../images/reef76.jpg";
import img89 from "../images/reef77.jpg";
import img90 from "../images/reef78.jpg";
import img91 from "../images/reef79.jpg";
import img92 from "../images/reef80.jpg";
import img93 from "../images/reef81.jpg";
import img94 from "../images/reef82.jpg";
import img95 from "../images/reef83.jpg";
import img96 from "../images/reef84.jpg";
import img97 from "../images/reef85.jpg";
import img98 from "../images/reef86.jpg";
import img99 from "../images/reef87.jpg";
import img100 from "../images/reef88.jpg";
import img101 from "../images/reef89.jpg";
import img102 from "../images/reef90.jpg";
import img103 from "../images/reef91.jpg";
import img104 from "../images/reef92.jpg";
import img105 from "../images/reef93.jpg";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img73,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  img88,
  img89,
  img90,
  img91,
  img92,
  img93,
  img94,
  img95,
  img96,
  img97,
  img98,
  img99,
  img100,
  img101,
  img102,
  img103,
  img104,
  img105,
];

const Gallery = () => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 650: 2, 800: 3 }}>
      <Masonry>
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            style={{ width: "100%", display: "block" }}
            className='p-1'
            alt=''
          />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default Gallery;
