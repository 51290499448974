import React from "react";
import Jumbotron from "../components/Jumbotron";
import ArtificialFeaturette from "../components/Featurette";
import MarineFeaturette from "../components/MarineFeaturette";

export default function Home() {
  return (
    <>
      <Jumbotron />
      <ArtificialFeaturette />
      <MarineFeaturette />
    </>
  );
}
