import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";

const styleLinks = {
  color: "#F6F6F6",
};

const Footer = () => {
  return (
    <Container
      fluid
      style={{ background: "#2D4059" }}
      fixed="bottom"
      className="py-3"
    >
      <Container className="pt-4">
        <Row style={styleLinks}>
          <Col>
            <h5>Reefmaker</h5>
            <p>
              David Walter <br /> Walter Marine <br /> PO Box 998 <br /> Orange
              Beach, AL 36561 <br />
            </p>

            <p></p>
          </Col>
          <Col>
            <h5>About</h5>
            <a href="/#artificial-reefs">Artificial Reefs</a>
            <br />
            <a href="/#marine-ecosystems">Marine EcoSystems</a>
            <br />
            <a href="/photos-videos">Photos & Videos</a>
          </Col>
          <Col>
            <h5>Policy</h5>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
            <p>California Policy</p>
          </Col>
          <Col id="contact">
            <div>
              <h5 className="text-white">
                Contact the Reefmaker/Walter Marine
              </h5>
              <Form
                method="POST"
                name="contact"
                netlify-honeypot="bot-field"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div style={{ display: "none" }}>
                  <label>
                    Don’t fill this out if you're human:{" "}
                    <input name="bot-field" />
                  </label>
                </div>
                <FloatingLabel
                  controlId="name"
                  label="Full Name"
                  className="mb-3 text-dark lead"
                >
                  <Form.Control
                    type="text"
                    placeholder="John/Jane Doe"
                    name="name"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="email"
                  label="Email Address"
                  className="mb-3 text-dark lead"
                >
                  <Form.Control
                    type="email"
                    placeholder="email@domain.com"
                    name="email"
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="message"
                  label="Message"
                  className="mb-3 text-dark lead"
                >
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Leave a message"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
                <Button type="submit">Submit</Button>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <p className="text-center lead pt-3 mb-0" style={styleLinks}>
            © 1986 - {new Date().getFullYear()} Reefmaker - Walter Marine All
            Rights Reserved
          </p>
          <p className="text-center pb-3 lead" style={styleLinks}>
            Designed with{" "}
            <FontAwesomeIcon icon={faHeart} beat style={{ color: "red" }} />{" "}
            using{" "}
            <a
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white-50"
            >
              React
            </a>{" "}
            by{" "}
            <a
              href="https://terryhunt.dev"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white-50"
            >
              Terry Hunt
            </a>
          </p>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
