import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import logo from "../../images/reefmaker.png";

const navLinkColor = {
  color: "#F6F6F6",
};

const menuLinks = [
  {
    name: "Artificial Reefs",
    to: "/#artificial-reefs",
    target: "_self",
  },
  {
    name: "Marine EcoSystems",
    to: "/#marine-ecosystems",
    target: "_self",
  },
  {
    name: "Photos & Videos",
    to: "/photos-videos",
    target: "_self",
  },
  {
    name: "Contact",
    to: "#contact",
    target: "_self",
  },
];

const socailMedia = [
  {
    name: "Facebook",
    to: "https://www.facebook.com/reefmaker",
    target: "_blank",
    icon: faFacebook,
  },
  {
    name: "Instagram",
    to: "https://www.instagram.com/thereefmaker/",
    target: "_blank",
    icon: faInstagram,
  },
];

export const TopMenu = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{ background: "rgb(45, 64, 89)" }}
        variant="dark"
      >
        <Container>
          <Navbar.Brand href="/">
            <img alt="Reefmaker Logo" src={logo} width={"100"} height={"100"} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              {menuLinks.map((link, index) => (
                <Nav.Link
                  style={navLinkColor}
                  key={index}
                  href={link.to}
                  target={link.target}
                >
                  {link.name}
                </Nav.Link>
              ))}
            </Nav>
            <Nav>
              {socailMedia.map((link, index) => (
                <Nav.Link
                  style={navLinkColor}
                  key={index}
                  href={link.to}
                  target={link.target}
                >
                  <FontAwesomeIcon
                    icon={link.icon}
                    style={{ height: "32px" }}
                  />
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
