import React from "react";
import { Container, Row } from "react-bootstrap";
import backgroundImage from "../images/ocean-floor.jpg";

const backgroundStyle = {
  background: `url(${backgroundImage}) no-repeat center center fixed`,
  height: "100vh",
  backgoundSize: "cover",
  backgroundPosition: "center",
};

const fontStyle = {
  color: "white",
  fontWeight: "bold",
  textShadow: "2px 2px 10px black",
};

export default function NotFound() {
  return (
    <>
      <Container fluid className='d-flex text-center' style={backgroundStyle}>
        <Container className='d-flex p-3 flex-column'>
          <Row className='m-auto' style={{ background: "rgba(0,0,0,0.5" }}>
            <h1 className='display-2' style={fontStyle}>
              404 Error - That is an empty floor that Reefmaker hasn't discover
              yet. Want to fill that empty floor?
            </h1>
          </Row>
        </Container>
      </Container>
    </>
  );
}
